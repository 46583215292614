<template>
  <div class="iss-main-grid">
    <!--    数据总览  -->
    <a-row :gutter="16" class="mb-16 rowClass">
      <a-col :span="6">
        <div class="statistics-card" flex-attr="task">
          <div class="statistics-title">
            <div class="mun fs-30 ">
              {{ totalRecharge ? totalRecharge : 0 }}
              <span class="yuan">个</span>
            </div>
            <div class="fontStyle">
              金币总充值数
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="statistics-card" flex-attr="user">
          <div class="statistics-title">
            <div class="mun fs-30">
              {{ totalGift ? totalGift : 0 }}
              <span class="yuan">个</span>
            </div>
            <div class="fontStyle">
              金币总赠送数
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="statistics-card" flex-attr="company">
          <div class="statistics-title">
            <div class="mun fs-30">
              {{ totalConsumption ? totalConsumption : 0 }}
              <span class="yuan">个</span>
            </div>
            <div class="fontStyle">
              金币总消耗数
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
      <a-col :span="6">
        <div class="statistics-card" flex-attr="company">
          <div class="statistics-title">
            <div class="mun fs-30">
              {{ totalStock ? totalStock : 0 }}
              <span class="yuan">个</span>
            </div>
            <div class="fontStyle">
              金币总存量数
            </div>
          </div>
          <div class="imgBox">
            <FileTextOutlined class="icon activeColor"/>
          </div>
        </div>
      </a-col>
    </a-row>

    <!--     筛选区域  -->
    <!--    <div class="statistics-card mb-16">-->
    <!--      -->
    <!--    </div>-->

    <!--     分页 -->
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #province="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #avatar="{ record }">
          <div  @click="handleName(record)" class="cursor-p" >
            <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
            <a-avatar :size="30" v-else>
              <template #icon><UserOutlined /></template>
            </a-avatar>
          </div>
        </template>
        <template #userName="{ record }">
          <div @click="handleName(record)" class="cursor-p" v-if="record.userName">{{record.userName}}</div>
          <div @click="handleName(record)" class="cursor-p" v-else>暂无</div>
        </template>

        <template #tradeType="{ record }">
<!--        数量样式处理   -->
          <div v-if="record.tradeType === 'add'">
            收入
          </div>
          <div v-if="record.tradeType === 'decrease'">
            支出
          </div>
        </template>

        <template #goldNum="{ record }">
          <!--        数量样式处理   -->
          <div v-if="record.tradeType === 'add'" class="activeColor">
            +
            <span>{{record.goldNum}}</span>
          </div>
          <div v-if="record.tradeType === 'decrease'">
            -
            <span>{{record.goldNum}}</span>
          </div>
        </template>

<!--      订单分类   -->
        <template #goldSource="{ record }">
          <!--        数量样式处理   -->
          <div v-if="record.goldSource === 1">注册</div>
          <div v-if="record.goldSource === 8">新手任务</div>
          <div v-if="record.goldSource === 5">新手指引</div>
          <div v-if="record.goldSource === 3">金币充值</div>
          <div v-if="record.goldSource === 2">邀请奖励</div>
          <div v-if="record.goldSource === 6">兑换使用时长</div>
          <div v-if="record.goldSource === 11">兑换云空间</div>
          <div v-if="record.goldSource === 9">短信发送消耗</div>
          <div v-if="record.goldSource === 10">短信退还</div>
          <div v-if="record.goldSource === 7">云空间结余</div>
        </template>


        <template #operation="{ record }">
          <operation :options="options" :record="record">
            <template #goDetail>
              <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
            </template>
            <template #more>
              <div class="primary-text cursor-p">
                <a-dropdown>
                  <a class="ant-dropdown-link" @click.prevent> 更多操作 </a>
                  <template #overlay>
                    <a-menu @click="clickOptions($event, record)">
                      <a-menu-item
                          v-for="item in itemOption"
                          :key="item.value"
                      >
                        <span class="padding-10">{{ item.label }}</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </template>
          </operation>
        </template>

      </grid>
    </div>
  </div>
  <gold-view
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleView"
  />
</template>

<script>
import { reactive, ref, toRefs, } from 'vue';
import { Row, Col, Modal, Menu, Dropdown, Avatar, message } from 'ant-design-vue';
import {
  UserOutlined,
  FileTextOutlined,
} from '@ant-design/icons-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {useRouter} from "vue-router";
import goldView from "@/views/user/components/goldView";
import {downloadExcel, setAvatar} from "@/utils";
import SearchForm from '@/components/searchForm';
import ordersApi from "@/api/orders.js";

export default {
  components: {
    ARow: Row,
    ACol: Col,
    // ARangePicker: DatePicker.RangePicker,
    // ASpace: Space,
    FileTextOutlined,
    Operation,
    Grid,
    goldView,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ADropdown: Dropdown,
    SearchForm,
    UserOutlined,
    AAvatar: Avatar,


  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      activeItem: {},
      // 总充值数
      totalRecharge: '',
      // 总赠送值数
      totalGift: '',
      // 总消耗数
      totalConsumption: '',
      //总存量数
      totalStock: '',


    });

    const clickOptions = ({ key }, record) => {
      console.log('更多操作', key, record);
      switch (key) {
        case 'order':
          router.push(`/user/${record.userId}?activeKey=3`)
          break;
        case 'invitation':
          router.push(`/user/${record.userId}?activeKey=2`)
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要封禁该账号吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
      }
    };

    // 金币总充值数查询
    ordersApi.getTotalRecharge('', {}).then(res => {
      if (res) {
        state.totalRecharge = res
      }
    });

    //总赠送值数
    ordersApi.getTotalGift('', {}).then(res => {
      if (res) {
        state.totalGift = res
      }
    });

    //总消耗数
    ordersApi.getTotalConsumption('', {}).then(res => {
      if (res) {
        state.totalConsumption = res
      }
    });

    // 总存量数
    ordersApi.getTotalStock('', {}).then(res => {
      if (res) {
        state.totalStock = res
      }
    });




    return {
      setAvatar,
      gridRef,
      ...toRefs(state),
      itemOption: [
        { value: 'order', label: 'ta的订单' },
        { value: 'invitation', label: 'ta的邀请' },
        { value: 'disable', label: '封禁账号' },
      ],
      items: [
        {
          key: 'goldSource',
          label: '订单分类',
          type: 'select',
          dataset: [
            { label: '注册', value: 1 },
            { label: '新手任务', value: 8 },
            { label: '新手指引', value: 5 },
            { label: '金币充值', value: 3 },
            { label: '邀请奖励', value: 2 },
            { label: '兑换使用时长', value: 6 },
            { label: '兑换云空间', value: 11 },
            { label: '短信发送消耗', value: 9 },
            { label: '短信退还', value: 10 },
            { label: '云空间结余', value: 7 },
          ],
        },
        {
          key: 'tradeType',
          label: '收支类型',
          type: 'select',
          dataset: [
            { label: '收入', value: 'add' },
            { label: '支出', value: 'decrease' },
          ],
        },
        //  需要补上日期时间筛选
        {
          key: 'date',
          timeKey: ['startCreateTime', 'endCreateTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        {
          key: 'content',
          label: '昵称',
          type: 'input',
        },

      ],
      columns: [
        { dataIndex: 'avatar', title: '头像', width: 60, ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true,
          slots: { customRender: 'userName' },
        },
        { dataIndex: 'packageTitle', title: '订单名称', width: 230, ellipsis: true },
        { dataIndex: 'tradeType', title: '收支类型', width: 180, ellipsis: true,
          slots: { customRender: 'tradeType' },
        },
        { dataIndex: 'goldNum', title: '金币数量', width: 180, ellipsis: true,
          slots: { customRender: 'goldNum' },
        },
        { dataIndex: 'goldSource', title: '订单分类', width: 120, ellipsis: true,
          slots: { customRender: 'goldSource' },
        },
        {
          dataIndex: 'createTime',
          title: '订单创建时间',
          width: 200,
          ellipsis: true,
        },

        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 80,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            ordersApi
                  .excelExportData('contact:export', {})
                .then(({ name, data }) => downloadExcel(name, data));
                // .then((data) => downloadExcel('用户金币详情数据.xlsx', data));
          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: (ids) => {
            if(ids.length) {
              ordersApi
                  .excelExportData('contact:export', { idList:[...ids]})
                  .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: ({ id }) => {
            // router.push({
            //   name: '',
            //   query: {
            //     id,
            //   },
            // });
            console.log(id, router);
          },
        },
        // {
        //   type: 'more',
        //   slotName: 'more',
        //   title: '更多操作',
        //   permission: 'user:more',
        //   fnClick: ({ id }) => {
        //     console.log(id);
        //   },
        // },
      ],
      url: ordersApi.goldUrl,
      gridHeight: document.body.clientHeight - 260,
      clickOptions,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleName: (record) => {
        return router.push(`/user/${record.userId}`)
      },
      handleView: (value) => {
        // 调取下载接口
        // if (ids.length) {
        ordersApi
            .excelExportData('contact:export', [value.id])
            .then(({ name, data }) => downloadExcel(name, data));
        // } else {
        //   message.info('请至少选中一条数据！');
        // }
        // state.visible = false
      },

      handleGotoView: ($event, record)=> {
        // 查询金币流水
        ordersApi.goleDetail('', record.id).then(res => {
          if (res) {
            // console.log('res金币流水', res)
            state.activeItem = res
            // console.log('dddd', state.activeItem)
          }
        });
        state.visible = true

      },

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
.yuan {
  font-size: 14px;
}


</style>